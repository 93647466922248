@import (less) "mixins.less";

ul#menu-main-navigation{
    width:100%;
    background:@blueLight;
    padding:0;
    margin:0;
    display:none;
    li{
        display:block;
        .centered();
        a{
            display:block;
            width:100%;
            padding:15px;
            .uppercase();
            .muli(20px);
            .uppercase();
            border-bottom:1px solid #fff;
            color:#fff;
            &:hover{
                }
            }
           &:last-child a{border:none!important;}
        }
    }
    
a#menu-toggle{
    color:#fff;
    font-size:24px;
    position:absolute;
    top:10px;
    right:10px;
    display:none;
    z-index:500;
    &.active{
        }
    }


@media only screen 
and (max-width : 900px) {
    ul#menu-main-navigation-1{
        li{
            a{font-size:12px;}
            }
        }
    }
    
@media only screen 
and (max-width : 800px) {
   a#menu-toggle{
       display:inline-block;
       }
    #menu-main-navigation-1{display:none;}
   
    }
 
@media only screen 
and (max-width : 768px) {
	#about{
		padding: 30px 0 30px 0;
		.centered();
		.central{
			padding:20px 10px 20px 10px!important;
			img.about-img{
			position:relative;
			top:0;
			left:0;
			margin-bottom: 35px;
			.inline-block();
			width: 90%;
			max-width:280px;
			background:#fff;
			padding: 20px 20px 0 20px;
			}
			}
		
		}
	#main-header{
		padding:30px 0 30px 0!important;
		}
	#banner{
		padding:100px 0 100px 0;
		background-attachment: scroll!important;
		}
	#practice{
		.centered();
		.central{
			padding:0 10px 0 10px!important;
			}
		.left,.right{
			padding:10px!important;
			}
		ul>li{
			padding-left:0;
			i{display: inline-block;position:relative;vertical-align: middle;top:0;margin-right:6px;}
			}
		}
	#victories{
		.central{
			padding:0 10px 0 10px!important;
			}
		}
		
	#location{
		.acf-map{
			height:300px!important;
			}
		}
    .mobile-center{.centered();}
    .mobile-hide{display:none!important;}
    }
   
@media only screen 
and (max-width : 480px) {



}